import { FC } from 'react';

import { useLinkComponent } from '@link';
import { ImageLinkRendering as Props } from '@sitecore/types/manual/NavigationBar';
import { OxxioLogo, VisuallyHidden } from '@sparky';

const ImageLink: FC<Props> = function ({ fields }) {
  const Link = useLinkComponent();
  return (
    <Link linkType={fields.link.value.linktype} linkValue={fields.link.value}>
      <a>
        <VisuallyHidden>{fields.link.value.text}</VisuallyHidden>
        <OxxioLogo />
      </a>
    </Link>
  );
};

export default ImageLink;
